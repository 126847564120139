'use strict';

angular.module('appApp')
  .directive('primoCombobox', function () {
    return {
      templateUrl: 'components/primo-combobox/primo-combobox.html',
      restrict: 'EA',
      scope: {
        model: '=model'
      },
      //link: function (scope, element, attrs) { }
      link: function () { }
    };
  });
